import React, { useEffect, useState } from 'react';
import Breadcumb from '../../Layouts/Breadcumb';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';

export default function Mcqresult() {
    const { mcqAnswerId } = useParams();
    const [result, setResult] = useState(null); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (mcqAnswerId) {
            const fetchResult = async () => {
                try {
                    const response = await axios.post('https://thecognize.com/admin/api/finalResult', {
                        mcqAnswerId: mcqAnswerId,
                        userId: 2,
                    });

                    if (response.data && response.data.data) {
                        setResult(response.data.data); 
                    } else {
                        setError('No data found in response.');
                    }
                } catch (err) {
                    setError('An error occurred while fetching data.');
                    console.error(err);
                } finally {
                    setLoading(false); 
                }
            };

            fetchResult();
        }
    }, [mcqAnswerId]);

    if (loading) {
        return <h4 className='text-center'>Loading...</h4>; 
    }

    if (error) {
        return <p className="text-center">{error}</p>; 
    }

    if (!result) {
        return <h4 className='text-center'>No Data Found</h4>; 
    }

    return (
        <>
            <Breadcumb pagetitle="Result" />
            <section className='py-5'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <div className='p-4 shadow-lg rounded-lg'>
                                <h3 className='text-center mb-4 h4'>Result</h3>
                                <table className='table table-bordered table-dark table-striped'>
                                    <tbody>
                                        <tr>
                                            <td>Total Question: </td>
                                            <td className='fw-semibold'>{result.totalQuestion}</td>
                                        </tr>
                                        <tr>
                                            <td>Attempted Questions: </td>
                                            <td className='fw-semibold'>{result.attemptQuestion}</td>
                                        </tr>
                                        <tr>
                                            <td>Correct Answers: </td>
                                            <td className='fw-semibold'>{result.correctAnswer}</td>
                                        </tr>
                                        <tr>
                                            <td>Wrong Answers: </td>
                                            <td className='fw-semibold'>{result.wrongAnswer}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='text-center mt-3 d-flex gap-2 justify-content-center'>
                                    <Link to="/result-answer" className="btn btn-success">View Your Answer</Link>
                                    <Link to="/certificate" className="btn btn-warning">View Certificate</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
