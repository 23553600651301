import React, { useEffect, useState } from 'react';
import Breadcumb from '../../Layouts/Breadcumb';
import Blogsidebar from './Blogsidebar';
import axios from 'axios';
import { useParams } from 'react-router-dom';
export default function BlogDetails() {
    const { blogId } = useParams();
    const [blogDetail, setBlogDetail] = useState(null); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (blogId) {
            const fetchBlogDetail = async () => {
                try {
                    const response = await axios.post('https://thecognize.com/admin/api/blogDetail', { blogId: blogId });
                    if (response.data && response.data.data) {
                        setBlogDetail(response.data.data); 
                    }
                } catch (err) {
                    setError('An error occurred while fetching blog.');
                    console.error(err);
                } finally {
                    setLoading(false); 
                }
            };

            fetchBlogDetail();
        }
    }, [blogId]);

    if (loading) {
        return <h4 className='text-center'>Loading...</h4>; 
    }

    if (error) {
        return <p className="text-center">{error}</p>; 
    }

    if (!blogDetail) {
        return <h4 className='text-center'>No Blog Data Found</h4>; 
    }
  return (
    <>
    <Breadcumb pagetitle="Blog Details"/>
    <section className='py-5'>
        <div className='container'>
            <div className='row g-4 justify-content-center'>
                <div className='col-lg-8'>
                    <div className='blog-box p-3 border rounded-4'>
                        {/* <div className='blocimg'>
                          <a href='javascript:void(0)'>
                            <img src={blogDetail.image} alt={blogDetail.title}  onError={(e) => e.currentTarget.src = '/assets/images/blog1.jpg'}/>
                            </a>
                        </div> */}
                        <div className='blogcontent'>
                            <h2 className='h4 mt-3 mb-2'><a href='javascript:void(0)' className='text-dark'>{blogDetail.title}</a></h2>
                            <div className='d-flex gap-3 dashoption mb-3'>
                                <div><a href='javascript:void(0)' className='text-muted'><i class="far fa-user"></i> Admin</a></div>
                                <div><a href='javascript:void(0)' className='text-muted'><i class="far fa-calendar-alt"></i> {blogDetail.created}</a></div>
                            </div>    
                            <p>{blogDetail.description}</p>
                           
                        </div>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <Blogsidebar/>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
