import React, { useState } from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";
import { exportDefaultSpecifier } from '@babel/types';
 
export default function Register() {
    const[name,setName]=useState("");
    const[email,setEmail]=useState("");
    const[password,setPassword]=useState("");
    const[confirmPassword,setConfirmPassword]=useState("");
    let [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const redirectUrl=useHistory();
    const validation=()=>{
        if (!name) return "Name is required.";
        if (!email) return "Email is required.";
        if (!password) return "Password is required.";
        if (!confirmPassword) return "Confirm Password is required.";
        return null; 
    };
    async function register()
    {
        const validationError = validation();
    if (validationError) {
        setError(validationError);
        return;
    }
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        const userData = { name, email, password,confirmPassword };

        try {
            const response = await fetch('https://thecognize.com/admin/api/register', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify(userData),
            });
            
            const result = await response.json();
           console.warn(result.success);
            if (result.success===true) {
                setSuccess("Registration successful!");
                setError(null); 
                redirectUrl.push('/login');
            }
            else{
               
                if (result.message && result.message.email &&result.message.email.length > 0) {
                    setError(result.message.email[0]); 
                }
                else if(result.message && result.message.password &&result.message.password.length > 0)
                {
                    setError(result.message.password[0]);
                }
                 else {
                    setError('Registration failed');
                }
            }
           
        } catch (error) {
          
            setError(error.message || 'An unexpected error occurred.');
            setSuccess(null); 
        }
    }

  return (
    <>
    <section className='logscreen'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-9 col-md-6'>
                      
                            <form className="p-4" >
                                <h3 className="mb-3 fw-bold text-center text-uppercase">Create an Account</h3>
                                <p className='text-center mb-4'>Welcome! Register to access the The Cognize</p>
                                {error && <h5 className='text-center text-danger'>{error}</h5>}
                             {success && <h5 className='text-center text-success'>{success}</h5>}
                                <div className="mb-3">
                                    <input type="text" className="form-control" name='name' value={name} onChange={(e)=>setName(e.target.value)} required placeholder='Name' autocomplete='off'/>
                                </div>
                                <div className="mb-3">
                                    <input type="email" className="form-control" name='email' value={email} onChange={(e)=>setEmail(e.target.value)} required placeholder='Email Id' autocomplete='off'/>
                                </div>
                                <div className="mb-3">
                                    <input type="password" className="form-control" name='password' value={password} onChange={(e)=>setPassword(e.target.value)}
                                    required placeholder='Password' autocomplete='off'/>
                                </div>
                                <div className="mb-3">
                                    <input type="password" className="form-control" name='confirmPassword' value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)}
                                    required placeholder='Confirm Password' autocomplete='off'/>
                                </div>
                                
                                <button type="button" className="btn btn-warning w-100 fw-semibold text-uppercase" onClick={register}>Continue</button>
                                <p className='mt-3 text-center'>Existing User?  <Link to='/login' className='text-success'>Login</Link></p>
                            </form>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    </section>  
    </>
  )
}
