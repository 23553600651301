import React, { useEffect, useState } from 'react';
import Breadcumb from '../../Layouts/Breadcumb';
import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';

export default function Macqlist() {
    const { mcqQuestionId } = useParams();
    const [mcqList, setmcqList] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        mcqTestId: '',
        questionId: [],
        correctAnswer: [],
        type: [],
        answer: {}  // Store answers for each question here
    });
    const [success, setSuccess] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const fetchMcqList = async () => {
            try {
                const response = await axios.get(`https://thecognize.com/admin/api/mcqQuestionList/${mcqQuestionId}`);
                setmcqList(response.data.data);
                setData(response.data.mcqQuestionList);
                const correctAnswers = response.data.mcqQuestionList.map(item => item.answer);
                const types = response.data.mcqQuestionList.map(item => item.type);

                setFormData(prevData => ({
                    ...prevData,
                    mcqTestId: response.data.data.mcqId,
                    correctAnswer: correctAnswers,
                    type: types,
                    questionId: response.data.mcqQuestionList.map(item => item.id)
                }));
            } catch (error) {
                console.error('Error fetching data:', error.response ? error.response.data : error.message);
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchMcqList();
    }, [mcqQuestionId]);

    const checkValidation = () => {
        // Ensure each question has an answer selected
        const unansweredQuestions = Object.keys(formData.answer).filter(
            questionId => formData.answer[questionId] === undefined || formData.answer[questionId].length === 0
        );

        if (unansweredQuestions.length > 0) {
            alert(`Please answer all questions. You have missed ${unansweredQuestions.length} question(s).`);
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const questionId = name.split('[')[1].split(']')[0];

        if (type === 'radio') {
            setFormData(prevData => ({
                ...prevData,
                answer: {
                    ...prevData.answer,
                    [questionId]: value  // Set answer for the specific questionId
                }
            }));
        } else if (type === 'checkbox') {
            setFormData(prevData => {
                const newAnswers = { ...prevData.answer };
                if (!newAnswers[questionId]) {
                    newAnswers[questionId] = [];
                }
                if (checked) {
                    newAnswers[questionId].push(value);
                } else {
                    newAnswers[questionId] = newAnswers[questionId].filter(v => v !== value);
                }
                return { ...prevData, answer: newAnswers };
            });
        }
    };

    async function submitAnswer() {
        if (!checkValidation()) {
            return;
        }

        const { questionId, correctAnswer, type, answer } = formData;

        // Transform the answer format to match the required structure for submission
        const submissionData = {
            mcqTestId: mcqList.mcqId,
            questionId: questionId,
            correctAnswer,
            type,
            answer: questionId.map(id => {
                const ans = answer[id];
                return Array.isArray(ans) ? ans.join(',') : ans;  // Join array answers as a string
            })
        };

        console.warn(submissionData); // For debugging the submission data

        try {
            const response = await fetch('https://thecognize.com/admin/api/mcqAnswer', {
                method: 'POST',
                body: JSON.stringify(submissionData),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const result = await response.json();
            console.log(result); // For debugging the result
            if (result.success === true) {
                setSuccess("Your answer was submitted successfully!");
                setError(null);
                history.push(`/result/${result.data.mcqAnswerId}`);
            }
        } catch (error) {
            setError('An unexpected error occurred.');
            setSuccess(null);
        }
    }

    if (loading) {
        return <h4 className='text-center'>Loading...</h4>;
    }

    if (error) {
        return <h4 className="text-center">{error}</h4>;
    }

    return (
        <>
            <Breadcumb pagetitle={`${mcqList.title}`} />
            <section className='py-5'>
                <div className='container'>
                    {error && <h5 className='text-center text-danger'>{error}</h5>}
                    {success && <h5 className='text-center text-success'>{success}</h5>}
                    <form>
                        <h3 className='h4 mb-4 border-bottom pb-3 border-light-subtle text-success'>{mcqList.title}</h3>
                        <input type="hidden" name="mcqTestId" value={mcqList.mcqId} />
                        {data.length > 0 ? (
                            data.map((item, index) => {
                                let questionContent;
                                if (item.type === 'radio') {
                                    questionContent = (
                                        <>
                                            <div className='mb-2 quebox'><label><input type='radio' name={`answer[${item.id}]`} value="A" onChange={handleChange} /> {item.A}</label></div>
                                            <div className='mb-2 quebox'><label><input type='radio' name={`answer[${item.id}]`} value="B" onChange={handleChange} /> {item.B}</label></div>
                                            <div className='mb-2 quebox'><label><input type='radio' name={`answer[${item.id}]`} value="C" onChange={handleChange} /> {item.C}</label></div>
                                            <div className='mb-2 quebox'><label><input type='radio' name={`answer[${item.id}]`} value="D" onChange={handleChange} /> {item.D}</label></div>
                                        </>
                                    );
                                } else if (item.type === 'checkbox') {
                                    questionContent = (
                                        <>
                                            <div className='mb-2 quebox'><label><input type='checkbox' name={`answer[${item.id}]`} value="A" onChange={handleChange} /> {item.A}</label></div>
                                            <div className='mb-2 quebox'><label><input type='checkbox' name={`answer[${item.id}]`} value="B" onChange={handleChange} /> {item.B}</label></div>
                                            <div className='mb-2 quebox'><label><input type='checkbox' name={`answer[${item.id}]`} value="C" onChange={handleChange} /> {item.C}</label></div>
                                            <div className='mb-2 quebox'><label><input type='checkbox' name={`answer[${item.id}]`} value="D" onChange={handleChange} /> {item.D}</label></div>
                                        </>
                                    );
                                }

                                return (
                                    <div className='questionList border border-light rounded p-3 mb-3' key={item.id}>
                                        <h4 className='h5 mb-3'>{index + 1}) {item.question}</h4>
                                        {questionContent}
                                    </div>
                                );
                            })
                        ) : (
                            <div className='questionList border border-light rounded p-3 mb-3'>
                                <h4 className='h5 mb-3 text-center'>No Data Found</h4>
                            </div>
                        )}
                        <div className='text-center mt-4'>
                            <button className='btn btn-success px-4 m-1' type='button' onClick={submitAnswer}>Submit</button>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
}
