import React,{useState,useEffect} from 'react'
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";
  import axios from 'axios';

export default function Qalist() {
    const[data,setData]=useState([]);
    const[error,setError]=useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        axios.get('https://thecognize.com/admin/api/questionList')
            .then((response) => {
                if (response.data.success) {
                    setData(response.data.data);
                } else {
                    setError(response.data.message);
                }
                setLoading(false);
            })
            .catch((error) => {
              
                console.error('Error fetching data:', error.response ? error.response.data : error.message);
                setError('Failed to fetch data');
                setLoading(false);
            });
    }, []);
    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="text-center">{error}</p>;
    }
  return (
    <>
    {data.length > 0 ? (
        data.slice(0,3).map((item,index)=>(
        <div className='qalistbox mb-4 shadow-lg' key={index}>
            <div className='d-flex'>
                <div className='qauserimg'>
                    <Link to={`/qa-details/${item.slugUrl}/${item.questionId}`}><img src='/assets/images/author-11.jpg'/></Link>
                </div>
                <div className='qapostinfo'>
                    <h3 className='h6 mb-1'><Link to={`/qa-details/${item.slugUrl}/${item.questionId}`} className='text-dark'>By: Arden Smith</Link></h3>
                    <ul className='mb-3'>
                        <li>{item.created}</li>
                        <li>Programmer</li>
                        {/* <li><i class="fa-light fa-message-lines"></i>3 </li> */}
                    </ul>
                </div>
            </div>
            <h3 className='qaposttitle mb-3'> <Link to={`/qa-details/${item.slugUrl}/${item.questionId}`} className='text-dark'>{item.title}</Link></h3>
            <p>{item.description}</p>
            <Link to={`/qa-details/${item.slugUrl}/${item.questionId}`} className='text-success fw-semibold'>View Details</Link>
        </div>
         ))
        ) :(
            <div className='qalistbox mb-4 shadow-lg'>
                 <div className='d-flex'>
                 <h3 className='qaposttitle mb-3'>No Data Found</h3>
                 </div>
                </div>
    )}
    </>
  )
}
